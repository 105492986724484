import { AktoerDto } from "@/types/aktoerDto";
import { getAktoerKontaktFullName } from "@/util/aktoer";

export const sortAktoerKontaktAlphabetically = (aktoerer: AktoerDto[]) => {
  return aktoerer.sort(sortKontaktAlphabetically);
};

const sortKontaktAlphabetically = (aktoerA: AktoerDto, aktoerB: AktoerDto) => {
  if (getAktoerKontaktFullName(aktoerA) < getAktoerKontaktFullName(aktoerB)) {
    return -1;
  }
  if (getAktoerKontaktFullName(aktoerA) > getAktoerKontaktFullName(aktoerB)) {
    return 1;
  }
  return 0;
};
