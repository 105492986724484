import Footer from "@/components/footer/Footer";
import Header from "@/components/header/Header";
import { UserContext } from "@/contexts/userContext";
import { UserInfo } from "@/types/userinfo";
import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

export default function Layout({userinfo, children}: {userinfo: UserInfo, children?: ReactElement}) {
  return (
    <>
      <Header userinfo={userinfo} />

      <div className={"container page-container"}>
        <main className="content" id="main-content">
          <UserContext.Provider value={userinfo}>
            {children}
            <Outlet />
          </UserContext.Provider>
        </main>
      </div>
      <Footer />
    </>
  );
}
