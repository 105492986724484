import { UserInfo } from "@/types/userinfo";
import LogoutButton from "../buttons/LogoutButton";
import LoginButton from "../buttons/LoginButton";

type UserViewProps = { readonly userinfo?: UserInfo; readonly mobileView?: boolean };

export default function UserView({ userinfo }: UserViewProps) {
  return (
    <>
      <p className="user">
        <strong className="username weight-semibold">{userinfo.aktoerOverblik?.navn}</strong>
      </p>
      {userinfo.isLoggedIn ? <LogoutButton csrfToken={userinfo.csrfToken} /> :
        <LoginButton csrfToken={userinfo.csrfToken} />}
    </>
  );
}
