import { PATHS } from "@/constants/paths";
type LoginButtonProps = {
  readonly csrfToken: string;
};
export default function LoginButton({ csrfToken }: LoginButtonProps) {
  return (
    <div>
      <form action={PATHS.LOGIN_PATH}>
        <input name="_csrf" type="hidden" value={csrfToken} />
        <div className={"form-group"}>
          <button type="submit" className={"button button-primary button-row"}>Log på</button>
        </div>
      </form>
    </div>
  );
}
