import { useNavigate } from "react-router-dom";
import { Kontakt } from "@/types/kontakt";
import { ChangeEvent, useEffect, useState } from "react";
import { PATHS } from "@/constants/paths";
import IngenKontakter from "@/components/loginhistorik/IngenKontakter";

export default function KontaktListe({ kontakter, currentKontakt }: { kontakter: Kontakt[], currentKontakt?: Kontakt }) {
  const navigate = useNavigate();
  const [valgtKontakt, setValgtKontakt] = useState<Kontakt | null>(currentKontakt ?? kontakter[0] ?? null);
  const updateValgtKontakt = (e: ChangeEvent<HTMLSelectElement>) => {
    setValgtKontakt(kontakter.find(kontakt => kontakt.aktoerUuid === e.currentTarget.value));
  };
  const getFullName = (kontakt: Kontakt) => {
    return `${kontakt.fornavn} ${kontakt.efternavn}`;
  };
  useEffect(() => {
    if (valgtKontakt) {
      navigate(PATHS.KONTAKT_LOGIN_HISTORIK + valgtKontakt.aktoerUuid);
    }
  }, [valgtKontakt]);
  return (
    kontakter?.length > 0 ?
      <div className={"form-group"}>
        <label className={"form-label"} htmlFor={"vaelg_kontakt"}>Vælg kontakt</label>
        <select defaultValue={valgtKontakt.aktoerUuid} className={"form-select"} id={"valeg_kontakt"}
                onChange={updateValgtKontakt}>
          {kontakter.map(kontakt => (
            <option key={kontakt.aktoerUuid} value={kontakt.aktoerUuid}
            >{getFullName(kontakt)}</option>
          ))}
        </select>
      </div> : <IngenKontakter />
  );
}
