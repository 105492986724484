import { NAVIGATION_PATHS, NavigationPaths } from "@/constants/paths";
import NavOption from "./NavOption";
import { matchRoutes, useLocation } from "react-router-dom";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import { UserInfo } from "@/types/userinfo";

export default function Nav({ userinfo, className }: { readonly userinfo: UserInfo, className: string }) {
  const { pathname } = useLocation();
  const { hasKontakter } = useAktoerKontakt(userinfo.aktoerOverblik);
  const currentPath = (path: NavigationPaths) => {
    return !!matchRoutes([...path.currentMatch?.map(current => ({
      path: current ?? path.path
    })) ?? [{ path: path.path }]], pathname);
  };

  const getNavPaths = () => {
    return NAVIGATION_PATHS.filter(nav => {
      if (nav.hideOnNoKontakt) {
        return !!hasKontakter;
      }
      return nav;
    });
  };

  return (
    <nav className={className} aria-label={"Hovedmenu"}>
      <ul className="mainmenu">
        {getNavPaths().map((path) => (
          <NavOption key={`nav${path.path}`} to={path.path} text={path.text}
                     current={currentPath(path)} />
        ))}
      </ul>
    </nav>
  );
}
