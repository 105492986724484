import { useRouteLoaderData } from "react-router-dom";
import Aktoerliste from "@/components/aktoer/Aktoerliste";
import { UserInfo } from "@/types/userinfo";

export default function AktoerOverblik() {
  const { aktoerOverblik } = useRouteLoaderData("main") as UserInfo;
  return (
    <>
      <h1>Min skole</h1>
      <p>Her kan du se de skoler og institutioner som har oprettet dig i Unilogin.</p>

      <Aktoerliste aktoerliste={aktoerOverblik.aktoerer} />
    </>
  );
}
