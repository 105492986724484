
import { PATHS } from "@/constants/paths";
import { redirect } from "react-router-dom";

export type RequestInitType = { method: "POST" | "PUT" | "GET"; token: string; body?: BodyInit }

export default async function baseFetch<T>(
  path: string,
  requestOptions?: RequestInitType,
): Promise<T & {trace?: string; message?: string;}> {
  let request = requestOptions ? buildRequestInit(requestOptions) : {};
    return fetch(path, request).then(async (response) => {
      if(response.ok) {
        return await response.json();
      }
      return handleResponseErrors(response.status);
    },
    async (error) => {
      throw new Error("Der skete en fejl " + error);
    });
}


export const buildRequestInit = (requestInit: RequestInitType): RequestInit => ({
  method: requestInit.method,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": requestInit.token,
  },
  body: requestInit.body,
});

const handleResponseErrors = (status: number) => {
  if (status === 403) {
    return redirect(PATHS.FRONT);
  } else {
    throw new Error("Der skete en fejl");
  }
};
