import { PATHS } from "@/constants/paths";
type LogoutButtonProps = {
  readonly csrfToken: string;
};
export default function LogoutButton({ csrfToken }: LogoutButtonProps) {
  return (
    <form method="post" action={PATHS.LOGOUT_PATH}>
      <input name="_csrf" type="hidden" value={csrfToken} />
      <button type="submit" className="button button-secondary d-print-none">
        Log af
      </button>
    </form>
  );
}
