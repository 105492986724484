import eleverDashboard from "@/assets/elever-dashboard.jpeg";
import LoginButton from "@/components/buttons/LoginButton";
import ImageRightCard from "@/components/cards/ImageRightCard";
import { UserInfo } from "@/types/userinfo";
import { useRouteLoaderData } from "react-router-dom";

export default function LoginScreen() {
  const userinfo = useRouteLoaderData("main") as UserInfo;
  return (
    <ImageRightCard imageAssetUrl={eleverDashboard} altText={"Elever der koncentrerer sig"}>
      <LoginButton csrfToken={userinfo.csrfToken} />
    </ImageRightCard>
  );
}
