import { AktoerlisteDto } from "@/types/aktoerlisteDto";

export const useAktoerKontakt = (aktoerListe: AktoerlisteDto) => {
  const hasKontakter = !!aktoerListe.aktoerer.find(aktoer => aktoer.elevForKontaktperson);
  const getKontakter = aktoerListe.aktoerer.flatMap(aktoer => aktoer.elevForKontaktperson).filter(kontakt => kontakt);
  const getCurrentKontakt = (aktoerId: string) => {
    return getKontakter.find(kontakt => kontakt.aktoerUuid === aktoerId);
  };
  return {
    hasKontakter,
    getKontakter,
    getCurrentKontakt
  };
};
