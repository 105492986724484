import { Link, To } from "react-router-dom";
type NavOptionProps = {
  readonly to: To;
  readonly text: string;
  readonly current: boolean;
}
export default function NavOption({ to, text, current }: NavOptionProps) {
  return (
    <li className={`${current ? "current active" : ""}`}>
      <Link to={to} className="nav-link">
        <span>{text}</span>
      </Link>
    </li>
  );
}
