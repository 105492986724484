import React from "react";
import LoginHistorikSkeletonLoader from "@/components/loginhistorik/LoginHistorikSkeletonLoader";
import { useNavigate, useParams } from "react-router-dom";
import LoginHistorikTableHeader from "@/components/loginhistorik/LoginHistorikTableHeader";
import LoginHistorikTable from "@/components/loginhistorik/LoginHistorikTable";
import useAktoerLoginHistorik from "@/store/aktoerLoginHistorikStore";
import { useShallow } from "zustand/react/shallow";
import LazyComponent from "@/components/lazy/LazyComponent";
import { PATHS } from "@/constants/paths";
import { replaceRouterParams } from "@/util/url";

export default function KontaktLoginHistorik() {
  const { aktoerId } = useParams();
  const { getAktoerLogin } = useAktoerLoginHistorik(useShallow(({ getAktoerLogin }) => ({ getAktoerLogin })));
  const navigate = useNavigate();
  const goToDetails = (clientUuid: string) => {
    navigate(replaceRouterParams(PATHS.KONTAKT_LOGIN_HISTORIK_AKTOER_DETALJER, { aktoerId, clientUuid }));
  };

  return <LazyComponent Component={LoginHistorikTable} promise={getAktoerLogin(aktoerId)}
                        additionalProps={{ onGoToDetails: goToDetails }}>
    <LoginHistorikSkeletonLoader amountOfColumns={5}>
      <LoginHistorikTableHeader />
    </LoginHistorikSkeletonLoader>
  </LazyComponent>;
}
